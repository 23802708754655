import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { ICompany } from 'src/app/models/company.models';
import { ICandidate } from 'src/app/models/candidate.models';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { environment } from 'src/environments/environment';
import { CandidateService } from 'src/app/services/candidate.service';
import { Observer } from 'rxjs';

interface NavItem { link: string, iconClass: string, title:string }
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  @Output() linkClicked: EventEmitter<void> = new EventEmitter<void>();
  company: ICompany | null = null
  candidate: ICandidate | null = null
  navItems: NavItem[] = []

  constructor(public router: Router,
    private authService: AuthService,
    private location: Location,
    private candidateService: CandidateService,
    private companyService: CompanyService) {
    const payload = this.authService.getDecodedAccessToken()
    if (payload?.company?._id) {
      this.navItems = [
        { iconClass: 'icon-home fs-30', link: '/private/dashboard',title:'Dashboard' },
        { iconClass: 'icon-case fs-30', link: '/private/offer',title:'Jobs'},
        { iconClass: 'icon-calendar fs-30', link: '/private/calendar' , title:'Calendar '},
        { iconClass: 'icon-company-list fs-30', link: '/admin/companies-list' , title:'Companies list'},
        { iconClass: 'icon-user-list fs-30', link: '/admin/users-list' , title:'Customers list '}
      ];
      this.loadCompanyProfile(payload?.company?._id)
    } else if (payload?.candidate?._id) {
      this.navItems = [
        { iconClass: 'icon-home fs-30', link: '/candidate/dashboard-candidate' , title:'Dashboard' },
        { iconClass: 'icon-case fs-30', link: '/candidate/offer-candidate', title:'Jobs' },
        { iconClass: 'icon-calendar fs-30', link: '/private/calendar' ,  title:'Calendar' },
        { iconClass: 'icon-company-list fs-30', link: '/admin/companies-list' , title:'Companies list'},
        { iconClass: 'icon-user-list fs-30', link: '/admin/users-list' , title:'Customers list '}
      ];
      this.loadUserProfile()
    }
  }

  loadCompanyProfile(companyId: string) {
    const observer: Observer<ICompany> = {
      next: (company: ICompany) => {
        if (company) {
          this.company = company;
        }
      },
      error: (err) => {
        console.error('Error fetching company by ID:', err);
      },
      complete: () => {
        // Optional: Handle completion if needed
      }
    };
    this.companyService.getCompanyById(companyId).subscribe(observer);
  }
  loadUserProfile() {
    const observer: Observer<ICandidate> = {
      next: (user: ICandidate) => {
        this.candidate = user;
      },
      error: (error) => {
        console.error('Error loading user profile', error);
      },
      complete: () => {
        // Optional: Handle completion if needed
      }
    };
    this.candidateService.getProfile().subscribe(observer);
  }

  goToDashboard() {
    if (this.company?._id) this.router.navigate(['/private/dashboard']);
    else if (this.candidate?._id) this.router.navigate(['/candidate/dashboard-candidate']);
    else {
      console.error('something went wrong + back()' + SideBarComponent.name)
      this.location.back()
    }
  }

  isActive(link: string): boolean {
    return this.router.isActive(link, true);
  }

  onLinkClick(link: string) {
    this.linkClicked.emit();
    this.router.navigate([link]);
  }

  goToProfile() {
    if (this.company?._id) this.router.navigate(['/private/about-company']);
    else if (this.candidate?._id) this.router.navigate(['/candidate/candidate-profile']);
    else console.error('something wnet wrong!sidebar')
  }

  ngOnInit() {
  }

  checkPictureExistance = () => this.company?.profileImg || this.candidate?.profileImg

  getPicture(): any {
    if (this.company?.profileImg)
      return environment.apiUrl + '/' + this.company?.profileImg
    else if (this.candidate?.profileImg)
      return environment.apiUrl + '/' + this.candidate?.profileImg
    else './assets/img/user.png'
  }

}
