import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlatpickrModule } from "angularx-flatpickr";
import { FullCalendarModule } from "@fullcalendar/angular";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { RatingModule } from "ngx-bootstrap/rating";
import { ModalModule } from "ngx-bootstrap/modal";
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AuthModule,
    ReactiveFormsModule,
    AuthModule,
    BrowserAnimationsModule,
    FlatpickrModule.forRoot(),
    FullCalendarModule,
    BrowserModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    MaterialModule,
    provideFirebaseApp(() => initializeApp({ "projectId": "talent-gate", "appId": "1:1019102710153:web:ada88e9dfe595db5ba7958", "storageBucket": "talent-gate.appspot.com", "apiKey": "AIzaSyDGTWZcBVz6l4lLkkUcKLKqOQE8MdewA14", "authDomain": "talent-gate.firebaseapp.com", "messagingSenderId": "1019102710153", "measurementId": "G-QEK4JB6GFY" })),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance())
  ],
  exports: [BrowserModule, AppRoutingModule, NgApexchartsModule, MaterialModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }, ScreenTrackingService, UserTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule { }
