<div class="header d-flex justify-content-end">
  <div class="d-flex">
    <div *ngIf="displaySearchBar" class="input-group search-group me-4 position-relative dropdown-notif"
      [formGroup]="searchForm">
      <span class="input-group-text">
        <em class="icon-loop fs-23"></em>
      </span>
      <input type="text" formControlName="search" (input)="onSearchChange(searchForm.value.search)"
        class="form-control search-input" placeholder="Search..." id="dropdownSearch" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">

      <!-- {{filteredItems.length}} -->
      <div class="dropdown-menu" aria-labelledby="dropdownSearch">
        <a *ngFor="let candidate of filteredItems" (click)="selectItem(candidate)" class="dropdown-item">
          <div class="d-flex align-items-center">
            <div class="d-flex">
              <span class="font-bold fs-20 text-primary">{{candidate.firstName + ' '+ candidate.lastName}}</span>
              <p class="font-regular fs-15 px-1 pt-1">
                {{candidate.headerTitle}}
              </p>
            </div>
            <div class="d-flex justify-content-center align-items-center px-2">
              <em class="icon-chevron-up fs-22 cursor-pointer"></em>
            </div>
          </div>
        </a>
      </div>

    </div>
    <div class="dropdown-notif h-fit-content" *ngIf="displaySearchBar">
      <button class="btn dropdown-toggle" type="button" id="dropdownNotification" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <em class="text-white icon-bell fs-25"></em>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownNotification">
        <ng-container *ngFor="let notification of notifications">
          <a class="dropdown-item" href="">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <ng-container *ngIf="notification?.candidate?.profileImg;else elseNoCompanyPicture">
                  <img [src]="getPicture(notification?.candidate?.profileImg)" alt="user" class="logo-preview">
                </ng-container>
                <ng-template #elseNoCompanyPicture>
                  <img class="w-100" src="./assets/img/product.png" alt="product">
                </ng-template>
                <p class="font-regular fs-15 ps-3 pe-3 pt-1">
                  <span class="font-bold ">{{notification?.candidate?.firstName +'
                    '+notification?.candidate?.lastName}}</span> has applied for {{notification?.offer?.name}}
                </p>
              </div>
              <div class="d-flex justify-content-center align-items-center px-2">
                <em class="icon-burger fs-22 cursor-pointer"></em>
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="position-relative">
      <em class="icon-logout text-white fs-22 cursor-pointer" (click)="logout()">
      </em>
    </div>
  </div>
</div>