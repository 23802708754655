import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICandidate } from 'src/app/models/candidate.models';
import { CandidateService } from 'src/app/services/candidate.service';
import { NotificationsService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  searchForm: FormGroup;

  constructor(public router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private candidateService: CandidateService) {
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  displaySearchBar: boolean = true
  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      const payload = this.authService.getDecodedAccessToken()
      if (payload?.candidate?._id) this.displaySearchBar = false
      if (payload?.company?._id) {
        this.getCompanyNotifications()
      }
    }
    this.searchForm.get('search')?.valueChanges
      .pipe(
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe((searchTerm) => {
        this.onSearchChange(searchTerm);
      });
  }

  notifications: any = []
  getCompanyNotifications() {
    this.notificationsService.findCompanyNotifs().subscribe({
      next: (response) => {
        console.log('Company notifications:', response);
        if (response.success) this.notifications = response.data
      },
      error: (error) => {
        console.error('Error fetching company notifications:', error);
      }
    });
  }
  getPicture(profileImg: any) {
    return environment.apiUrl + '/' + profileImg;
  }

  showWarning: boolean = false
  filteredItems: ICandidate[] = [];
  onSearchChange(searchTerm: string) {
    searchTerm = searchTerm.toLowerCase();

    // Check if the length of the search term is greater than or equal to 3 before searching
    if (searchTerm.length >= 3) {
      this.showWarning = false;

      this.loadProfiles(searchTerm);

    } else {
      if (searchTerm !== '') this.showWarning = true;
      this.filteredItems = [];
    }
  }
  loadProfiles(searchTerm: string) {
    this.candidateService.getAllUsers(0, 10, searchTerm).subscribe({
      next: (response: { success: boolean, users: ICandidate[] }) => {
        if (response.success)
          this.filteredItems = response.users.map((candidate) => candidate);
      },
      error: (error) => {
        console.error('Error fetching skills:', error);
      },
      complete: () => {
        // Optional: Handle completion if needed
      }
    })
  }

  selectItem(item: ICandidate) {
    console.log('Selected item:', item);
    this.searchForm.patchValue({ search: '' });
    this.filteredItems = [];
    // naviagte to profile 
    // this.router.navigate(['/login']);
    if (item?._id) this.viewCandidateProfile(item)
  }
  viewCandidateProfile(candidate: ICandidate) {
    this.router.navigate(['/private/about-candidate/' + candidate._id])
  }
  logout() {
    this.authService.removeAccessToken()
    this.router.navigate(['/login']);
  }
}
