import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { BehaviorSubject, Observer } from 'rxjs';
import { User } from 'firebase/auth';
import { environment } from 'src/environments/environment';

import { Messaging, getToken, onMessage } from '@angular/fire/messaging';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isPasswordVisible = false;
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]], // Adjust the minimum length as needed
  });
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  private readonly _messaging = inject(Messaging);
  private readonly _message: any = new BehaviorSubject<any>(undefined);

  title = 'fcm-angular-demo';
  message$ = this._message.asObservable();

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    if (this.authService.isAuthenticated()) {
      const payload = this.authService.getDecodedAccessToken();
      if (payload?.company?._id) this.router.navigate(['/private/dashboard']);
      else if (payload?.candidate?._id)
        this.router.navigate(['/candidate/dashboard-candidate']);
      else {
        console.error(
          'something went wrong + back() + constructor' + LoginComponent.name
        );
      }
    }
  }

  paramValue: string = '';

  companyToken: string = ""
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      // Access route parameters here
      const paramValue = params['type']; // replace 'paramName' with the actual parameter name
      if (!paramValue) {
        console.error('something went wrong + back()' + LoginComponent.name);
        this.location.back();
      }
      // Perform actions based on the parameter value
      console.log('Route parameter value:', paramValue);
      this.paramValue = paramValue;
    });


    // Request permission to receive notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted')
        console.log('Permission granted');
      else if (permission === 'denied')
        console.log('Permission denied');
    });

    // Get the current FCM token
    getToken(this._messaging)
      .then((token) => {
        console.log('Token', token);
        // You can send this token to your server and store it there
        // You can also use this token to subscribe to topics
        this.companyToken = token
      })
      .catch((error) => console.log('Token error', error));

    // Listen for messages from FCM
    onMessage(this._message, {
      next: (payload) => {
        console.log('Message', payload);
        // You can display the message or do something else with it
      },
      error: (error) => console.log('Message error', error),
      complete: () => console.log('Done listening to messages')
    });
  }



  navigateToSignUp() {
    this.router.navigate(['/auth/signup/' + this.paramValue]);
  }

  navigateToHomePage() {
    this.router.navigate(['/public/hero']);
  }

  logIn(event: Event) {
    event.preventDefault();
    console.log('Form', this.loginForm.value);

    const observer: Observer<any> = {
      next: (authResp: {
        accessToken?: string;
        company?: boolean;
        success?: boolean;
        admin?: boolean;
      }) => {
        if (authResp?.accessToken && authResp?.success) {
          this.authService.setAccessToken(authResp.accessToken);
          if (authResp.company) {

            this.router.navigate(['/private/dashboard']);
          } else if (authResp.admin) {
            this.router.navigate(['/admin/users-list']);
          } else {
            this.router.navigate(['/candidate/dashboard-candidate']);
          }
        } else {
          throw new Error("There's something wrong!");
        }
      },
      error: (error) => {
        console.error('Error during login:', error);
      },
      complete: () => {
        // Optional: Handle completion if needed
      },
    };

    this.authService
      .login(this.loginForm.value['email'], this.loginForm.value['password'],this.companyToken)
      .subscribe(observer);
  }

}