// notifications.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private apiUrl = environment.apiUrl + '/notifications';

    constructor(private http: HttpClient) { }

    getCount(): Observable<any> {
        const url = `${this.apiUrl}/count`;
        return this.http.get<any>(url);
    }

    findCompanyNotifs(): Observable<any> {
        const url = `${this.apiUrl}/company`;
        return this.http.get<any>(url);
    }
}
