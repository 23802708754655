export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3000',
  apiUrl: 'https://api-dev-v2.talentsgates.website',
  firebaseConfig: {
    apiKey: "AIzaSyDGTWZcBVz6l4lLkkUcKLKqOQE8MdewA14",
    authDomain: "talent-gate.firebaseapp.com",
    projectId: "talent-gate",
    storageBucket: "talent-gate.appspot.com",
    messagingSenderId: "1019102710153",
    appId: "1:1019102710153:web:ada88e9dfe595db5ba7958",
    measurementId: "G-QEK4JB6GFY",
    vapidKey : "BD9KoC9mUt7w3-2D1tgfXhvswB18CNF5MLLaUI5ktTKcTquZ1dUGfrShc1u2ub"
  }
};
